import {Button, Input, message, Space, Table, Tabs} from "antd";
import {cancelTransactionApi, createTransferApi, getTransactionApi} from "../../../api/api";
import {SearchOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Style from "../../../assets/css/panel.module.css";
import {ExportToCSV} from "../../../api/common";

const History = ({type, style, id}) => {

    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});

    useEffect(()=>{
        handleTabChange(id)
    },[])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };

    const getColumnSearchProps = (dataIndex, title) => (
        title === "Status" ?
            {
                filters: [
                    {
                        text: 'Approved',
                        value: 'approved',
                    },
                    {
                        text: 'Declined',
                        value: 'declined',
                    },
                    {
                        text: 'Cancelled',
                        value: 'cancelled',
                    }
                ],
                onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            } : title === "Type" ?
                {
                    filters: [
                        {
                            text: 'Deposit',
                            value: 'deposit',
                        },
                        {
                            text: 'Dormant Fee',
                            value: 'dormant fee',
                        },
                        {
                            text: 'Balance Correction',
                            value: 'balance correction',
                        },
                        {
                            text: 'Bonus Conversion',
                            value: 'bonus conversion',
                        },
                        {
                            text: 'Bonus In',
                            value: 'bonus in',
                        },
                        {
                            text: 'Bonus Out',
                            value: 'bonus out',
                        },
                        {
                            text: 'Cashback Transfer to Account',
                            value: 'cashback transfer to account',
                        },
                        {
                            text: 'Cashback Withdrawal',
                            value: 'cashback withdrawal',
                        },
                        {
                            text: 'CPA Payment',
                            value: 'cpa payment',
                        },
                        {
                            text: 'CPL Payment',
                            value: 'cpl payment',
                        },
                        {
                            text: 'Credit',
                            value: 'credit',
                        },
                        {
                            text: 'Credit Expired',
                            value: 'credit expired',
                        },
                        {
                            text: 'IB Correction',
                            value: 'ib correction',
                        },
                        {
                            text: 'IB PAMM Fee Payment',
                            value: 'ib pamm fee payment',
                        },
                        {
                            text: 'IB Transfer to Account',
                            value: 'ib transfer to account',
                        },
                        {
                            text: 'IB Transfer to Account Out',
                            value: 'ib transfer to account out',
                        },
                        {
                            text: 'IB Withdrawal',
                            value: 'ib withdrawal',
                        },
                        {
                            text: 'Negative Correction Balance',
                            value: 'negative correction balance',
                        },
                        {
                            text: 'Negative Correction Credit',
                            value: 'negative correction credit',
                        },
                        {
                            text: 'PAMM Immediate Execution Fee',
                            value: 'pamm immediate execution fee',
                        },
                        {
                            text: 'PL',
                            value: 'pl',
                        },
                        {
                            text: 'Pool Distribution',
                            value: 'pool distribution',
                        },
                        {
                            text: 'PSP Fee',
                            value: 'psp fee',
                        },
                        {
                            text: 'Third Party Transfer In',
                            value: 'third party transfer in',
                        },
                        {
                            text: 'Third Party Transfer Out',
                            value: 'third party transfer out',
                        },
                        {
                            text: 'Transfer In',
                            value: 'transfer in',
                        },
                        {
                            text: 'Transfer Out',
                            value: 'transfer out',
                        },
                        {
                            text: 'Withdrawal',
                            value: 'withdrawal',
                        },
                        {
                            text: 'Withdrawal Cancel',
                            value: 'withdrawal cancel',
                        },
                        {
                            text: 'Withdrawal Decline',
                            value: 'withdrawal decline',
                        }
                    ],
                    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
                }
                : {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                    <div
                        style={{
                            padding: 8,
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                    >
                        <Input
                            ref={searchInput}
                            placeholder={`Search ${title}`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{
                                marginBottom: 8,
                                display: 'block',
                            }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{
                                    width: 90,
                                }}
                            >
                                Search
                            </Button>
                            <Button
                                onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                                size="small"
                                style={{
                                    width: 90,
                                }}
                            >
                                Reset
                            </Button>
                        </Space>
                    </div>
                ),
                filterIcon: (filtered) => (
                    <SearchOutlined
                        style={{
                            color: filtered ? '#1677ff' : undefined,
                        }}
                    />
                ),
                onFilter: (value, record) =>
                    record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownOpenChange: (visible) => {
                    if (visible) {
                        setTimeout(() => searchInput.current?.select(), 100);
                    }
                },
                    sorter: (a, b) => {
                        const valueA = a[dataIndex];
                        const valueB = b[dataIndex];

                        if (!isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB))) {
                            return parseFloat(valueA) - parseFloat(valueB);
                        } else {
                            return valueA && valueA.localeCompare(valueB);
                        }
                    },
                sortDirections: ['ascend', 'descend'],
                render: (text) => <span>{text}</span>
            }
    );

    const createTransfer = async (type, filter) => {
        const data = {
            tableConfig: {
                filters: [
                    {
                        field: "col_processed_transfers_table_configurator_status",
                        modificator: "Equals",
                        value: [
                            filter
                        ]
                    }
                ],
                "segment": {
                    "limit": "500",
                    "offset": 0
                },
                "sorting": {
                    "field": "string",
                    "direction": "DESC"
                },
                "csv": false,
                "withTotals": false
            }
        }
        await createTransferApi(data, token).then((result)=>{
            const columnsWithSearchProps = result.data.columns
                .filter((x) => (x.dataIndex = x.key) && (x.title != "Approve Reason"))
                .map((column) => ({
                    ...column,
                    ...getColumnSearchProps(column.key, column.title),
                }));


            const apiData = result.data.rows;
            const antDesignTableData = apiData.map((item, index) => {
                const dataObj = {};
                item.data.forEach((entry) => {
                    dataObj[entry.key] = entry.value;
                });
                dataObj.key = index.toString();
                return dataObj;
            });

            setColumns(columnsWithSearchProps)
            setRows(antDesignTableData)

            setLoading(false)
        }).catch(()=>{

        })
    }

    const cancelTransaction = async (row) => {
        const id = Number(row.col_columndefinitions_idcolumndefinition_76727b4a9f351b3a337ec8c0093c69c0 || 0);

        await cancelTransactionApi(id, token).then(()=>{
            message.success("Cancelled Successfully!")
            getTransaction("PendingTransactions", "pending check");
            getTransaction("TransactionsHistory", null);
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getTransaction = async (type, filter) => {
        const data = {
            tableConfig: {
                filters: [
                    {
                        field: "col_columndefinitions_statuscolumndefinition_17d237166a7009c7e998dc90a89836f5",
                        modificator: "Equals",
                        value: filter
                    }
                ],
                "segment": {
                    "limit": "500",
                    "offset": 0
                },
                "sorting": {
                    "field": "field",
                    "direction": "DESC"
                },
                "csv": false,
                "withTotals": false
            }
        }

        await getTransactionApi(data, token).then((result)=>{
            const columnsWithSearchProps = result.data.columns
                .filter((x) => (x.dataIndex = x.key) && (x.title != "Approve Reason"))
                .map((column)=>{
                    if(column.title === "Can Be Canceled"){
                        return {
                            ...column,
                            title: "",
                            render: (text, value) => (text == 1 ? <Button onClick={()=> cancelTransaction(value)} shape="round" danger data-title={column.title}>{translate('cancel')}</Button> : null),
                        }
                    } else {
                        return {
                            ...column,
                            ...getColumnSearchProps(column.key, column.title)
                        }
                    }
                })


            const apiData = result.data.rows;
            const antDesignTableData = apiData.map((item, index) => {
                const dataObj = {};
                item.data.forEach((entry) => {
                    dataObj[entry.key] = entry.value;
                });
                dataObj.key = index.toString();
                return dataObj;
            });

            setColumns(columnsWithSearchProps)
            setRows(antDesignTableData)

            setLoading(false)

        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleTabChange = (x) => {
        setLoading(true)

        if(x === 1){
            getTransaction("PendingTransactions", "pending check");
        } else if(x === 2){
            createTransfer("PendingTransfers", "pending");
        } else if(x === 3){
            getTransaction("TransactionsHistory", null);
        } else if(x === 4){
            createTransfer("TransfersHistory",  "approved");
        }
    }

    const handleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
    };

    const pending = [
        {
            key: 1,
            label: translate('funds_pending_transactions'),
            children: <>
                <ExportToCSV data={rows} dir={dir} translate={translate} name="wingo_history"/>
                <Table onChange={handleChange} className={Style.LinksTable} scroll={{x: 560}} dataSource={rows} columns={columns} loading={loading}/>
                </>,
        },
        {
            key: 2,
            label: translate('funds_pending_transfers'),
            children: <>
                <ExportToCSV data={rows} dir={dir} translate={translate} name="wingo_history"/>
                <Table onChange={handleChange} className={Style.LinksTable} scroll={{x: 560}} dataSource={rows} columns={columns} loading={loading}/>
            </>,
        }
    ];

    const approved = [
        {
            key: 3,
            label: translate('funds_transactions_history'),
            children: <>
                <ExportToCSV data={rows} dir={dir} translate={translate} name="wingo_history"/>
                <Table onChange={handleChange} className={Style.LinksTable} scroll={{x: 560}} dataSource={rows} columns={columns} loading={loading}/>
            </>,
        },
        {
            key: 4,
            label: translate('funds_transfers_history'),
            children: <>
                <ExportToCSV data={rows} dir={dir} translate={translate} name="wingo_history"/>
                <Table onChange={handleChange} className={Style.LinksTable} scroll={{x: 560}} dataSource={rows} columns={columns} loading={loading}/>
            </>,
        }
    ];

    return (
        <Tabs
            style={style}
            items={type === "pending" ? pending : type === "approved" ? approved : null}
            onChange={handleTabChange}
        />
    )
}

export default History;