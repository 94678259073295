import Style from "../../../assets/css/panel.module.css";
import Globe from "../../../assets/img/icon/globe-icon.svg";
import {Dropdown} from "antd";
import {setSetting} from "../../../redux/actions/user";
import {changeLanguageApi} from "../../../api/api";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import {useWindowSize} from "../windowSize";
import {useSearchParams} from "react-router-dom";

const Language = ({style}) => {

    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth?.token || null);
    const setting = useSelector((state) => state.auth?.setting || null);
    const [language , setLanguage] = useState({});
    const { i18n } = useTranslation();
    const {device} = useWindowSize();
    const [searchParams, setSearchParams] = useSearchParams();
    const lang = searchParams.get("lang");

    useEffect(()=>{
        getCurrentLang();
    },[setting]);

    useEffect(()=>{
        if(lang){
            const find = langs.find((x)=>x.value === lang);
            if(find){
                handleChangeLang(find)
            }
        }
    },[lang]);

    const langs = [
        {
            key: 1,
            label: 'English',
            value: 'en',
            dir: 'ltr',
            icon: null
        },
        {
            key: 2,
            label: 'فارسی',
            value: 'fa',
            dir: 'rtl',
            icon: null
        },
        {
            key: 3,
            label: 'العربية',
            value: 'ar',
            dir: 'rtl',
            icon: null
        },
        {
            key: 4,
            label: 'Español',
            value: 'es',
            dir: 'ltr',
            icon: null
        },
        {
            key: 5,
            label: 'Français',
            value: 'fr',
            dir: 'ltr',
            icon: null
        }
    ];

    const getCurrentLang = () => {
        const current = setting?.lang;
        const lang = langs.find((x)=>x.value == current);
        if(lang){
            setLanguage(lang)
        }
    }

    const handleChangeLang = ({key}) => {
        const lang = langs.find((x)=> x.key == key);
        if(lang){
            i18n.changeLanguage(lang.value);
            dispatch(setSetting({...setting, lang: lang.value, dir: lang?.dir}))
            if(token){
                changeLanguage(lang.value)
            }
        }
    }

    const changeLanguage = async (lang) => {
        const data = {
            language: lang
        }
        await changeLanguageApi(data, token).then(()=>{
        }).catch((error)=>{
            console.log(error)
        })
    }

    return (
        <Dropdown
            menu={{items: langs, onClick: handleChangeLang, defaultSelectedKeys: [String(language.key)], selectable: true}}
        >
            <div style={style} className={`${Style.SwitchLang} d-flex align-items-center`}>
                <img src={Globe}/>
                {device != "mobile" ? <span>{language.label}</span>: null}
            </div>
        </Dropdown>
    )
}

export default Language;